import { useMemo } from "react";
import { ProblemPopupButton } from "src/components/pages/main/problem_popup_button";
import { MenuCategory, ProblemDefinition } from "src/core/problem";
import { PROBLEMS } from "src/data/problem_data";

export const MainPage = () => {
  const problemByCategory = useMemo(
    () => aggregateProblemByCategory(PROBLEMS),
    []
  );

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        // backgroundImage: "linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)), url(/images/background.jpg)",
        // backgroundImage: "url(/images/background.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        // backdropFilter: "blur(30px)",
        // WebkitBackdropFilter: "blur(30px)",
        backgroundAttachment: "fixed",
      }}
    >
      {Object.keys(problemByCategory).map((category) => {
        return (
          <div
            key={category}
            style={{
              marginBottom: "10px",
              paddingLeft: "2vw",
              paddingRight: "2vw",
            }}
          >
            <h2
              style={{
                WebkitTextStroke: "0.2px #FFF",
                // textStro: 1px #FFF;
              }}
            >
              {categoryToJapanese[category as MenuCategory]}
            </h2>
            {problemByCategory[category].map((problem) => {
              return (
                <ProblemPopupButton problemID={problem.id} key={problem.id} />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const aggregateProblemByCategory = (problems: typeof PROBLEMS) => {
  const result: { [key: string]: ProblemDefinition[] } = {};
  problems.forEach((problem) => {
    if (result[problem.category]) {
      result[problem.category].push(problem);
    } else {
      result[problem.category] = [problem];
    }
  });
  return result;
};

const categoryToJapanese: { [category in MenuCategory]: string } = {
  food: "メイン料理",
  "strong-alcohol": "強めのお酒",
  speed: "スピードメニュー",
  "weak-alcohol": "チューハイ",
};
