import { Button } from "@mui/material";
import { useMemo } from "react";

export type SquareStatus =
  | "active"
  | "inactive"
  | "suggestedUp"
  | "suggestedDown"
  | "suggestedLeft"
  | "suggestedRight";

export const Square = (props: {
  character?: string;
  onClick: () => void;
  status: SquareStatus;
}) => {
  const size = "60px";

  const variant = useMemo(() => {
    switch (props.status) {
      case "inactive":
        return "outlined";
      default:
        return "contained";
    }
  }, [props.status]);

  const color = useMemo<
    | "primary"
    | "secondary"
    | "inherit"
    | "error"
    | "info"
    | "success"
    | "warning"
  >(() => {
    switch (props.status) {
      case "active":
        return "primary";
      case "suggestedUp":
        return "secondary";
      case "suggestedDown":
        return "success";
      case "suggestedLeft":
        return "warning";
      case "suggestedRight":
        return "error";
      default:
        return "primary";
    }
  }, [props.status]);

  return (
    <Button
      style={{
        width: size,
        height: size,
        borderWidth: "1px",
        // borderColor: color,
        borderStyle: "solid",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        padding: 0,
        // backgroundColor: color,
      }}
      onClick={props.onClick}
      variant={variant}
      color={color}
    >
      {props.character}
    </Button>
  );
};
