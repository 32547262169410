import { Button } from "@mui/material";
import { useModal } from "src/components/organisms/context_modal/context_modal";
import { Problem } from "src/components/pages/main/propblem";
import { ProblemID, useProblemCookie } from "src/core/problem";

export type ProblemPopupButtonProps = {
  problemID: ProblemID;
};

export const ProblemPopupButton = (props: ProblemPopupButtonProps) => {
  const modalController = useModal();
  const [cookies] = useProblemCookie(props.problemID);
  return (
    <Button
      variant={cookies?.status === "solved" ? "outlined" : "contained"}
      style={{ margin: "2px" }}
      onClick={() => {
        modalController.open(
          <Problem
            imagePath={`/images/problems/${props.problemID}.jpg`}
            problemID={props.problemID}
          />
        );
      }}
      key={props.problemID}
    >
      {props.problemID}
    </Button>
  );
};
