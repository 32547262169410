import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { headerIndex } from "src/store/recoil_state";

export const Header = () => {
  const [header, setHeaderIndex] = useRecoilState(headerIndex);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setHeaderIndex(newValue);
  };

  const navigate = useNavigate();

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={header}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="辞書検索"
            // {...a11yProps(0)}
            onClick={() => {
              navigate("/");
            }}
          />
          <Tab
            label="50音検索"
            // {...a11yProps(1)}
            onClick={() => {
              navigate("/50on");
            }}
          />
          <Tab
            label="[test]include"
            // {...a11yProps(1)}
            onClick={() => {
              navigate("/include");
            }}
          />
        </Tabs>
      </Box>
    </Box>
  );
};
