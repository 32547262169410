import { Route, Routes } from "react-router-dom";
import { JapaneseSyllabaryPage } from "src/components/pages/japanese_syllabary/japanse_syllabary_page";
import { MainPage } from "src/components/pages/main/main_page";
import { NotFoundPage } from "src/components/pages/not_found/not_found_page";
import { IncludePage } from "src/components/pages/test_include/include_page";
import { HeaderTemplate } from "src/components/templates/header_template";

export const AppRoute: React.FC = () => {
  return (
    <Routes>
      <Route path={`/`} element={<HeaderTemplate />}>
        <Route path="/" element={<MainPage />} />
        <Route path="/50on" element={<JapaneseSyllabaryPage />} />
        {/* test page. */}
        <Route path="/include" element={<IncludePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};
