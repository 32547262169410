import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "src/style/default_style.css";
import "src/style/google.css";
// import "nazo-wasm/nazo_wasm_bg.wasm";
// import "nazo-wasm/nazo_bg.wasm";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// please see https://swiperjs.com/react#usage
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import init, { hello } from "nazo-wasm";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { ModalContext } from "src/components/organisms/context_modal/context_modal";
import { initializeFirebaseApp } from "src/firebase/firebase";
import { AppRoute } from "src/route";
import { CookiesProvider } from "react-cookie";
import { SnackBarContext } from "src/components/organisms/context_snackbar/context_snackbar";
import { ThemeProvider } from "@mui/material";
import { izakayaTheme } from "src/components/organisms/theme/theme";

init()
  .then(() => {
    hello();
    console.log("OK!");
  })
  .catch((e) => {
    console.error(e);
  });

initializeFirebaseApp();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

/**
 * @link https://mui.com/material-ui/react-modal/
 */

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <ThemeProvider theme={izakayaTheme}>
          <SnackBarContext>
            <ModalContext>
              <BrowserRouter>
                <AppRoute />
              </BrowserRouter>
            </ModalContext>
          </SnackBarContext>
        </ThemeProvider>
      </CookiesProvider>
    </RecoilRoot>
  </React.StrictMode>
);
