import { SnackbarProps } from "@mui/material";
import { ReactNode } from "react";
import { atom } from "recoil";
import { FirebaseUser } from "src/firebase/auth/user";

export const modalComponent = atom<ReactNode>({
  key: "modalComponent",
  default: <></>,
});

export const modalSwitch = atom({
  key: "modalSwitch",
  default: false,
});

export const loginSwitch = atom<FirebaseUser | undefined>({
  key: "loginSwitch",
  default: undefined,
});

export const snackBarSwitch = atom<SnackbarProps>({
  key: "snackBarSwitch",
  default: {
    open: false,
  },
});

export const headerIndex = atom({
  key: "headerIndex",
  default: 0,
});
