import { useMediaQuery, useTheme } from "@mui/material";

/**
 * media query simple wrapper.
 */
export const useMobile = () => {
  const theme = useTheme();
  const matches = useMediaQuery(() => {
    console.log(theme);
    return theme.breakpoints.down("sm");
  });
  return matches;
};

/**
 * media query simple wrapper.
 */
export const useTablet = () => {
  const theme = useTheme();
  const matches = useMediaQuery(() => {
    return theme.breakpoints.down("md");
  });
  return matches;
};
