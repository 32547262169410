import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { ReactNode } from "react";
import { SuggestedWords } from "src/components/pages/japanese_syllabary/japanse_syllabary_page";

export type Direction = "up" | "down" | "left" | "right";

export type UpDownLeftRightCheckBoxProps = {
  up: boolean;
  down: boolean;
  left: boolean;
  right: boolean;
  onCheckBoxClicked: (direction: Direction) => void;
  suggestedWords: SuggestedWords;
};
export const UpDownLeftRightCheckBox = (
  props: UpDownLeftRightCheckBoxProps
) => {
  return (
    <FormGroup>
      <NaiveBox>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.up}
              onClick={() => {
                props.onCheckBoxClicked("up");
              }}
            />
          }
          label="上"
        />
        <TextField value={props.suggestedWords.up} disabled />
      </NaiveBox>
      <NaiveBox>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.down}
              onClick={() => {
                props.onCheckBoxClicked("down");
              }}
            />
          }
          label="下"
        />
        <TextField value={props.suggestedWords.down} disabled />
      </NaiveBox>
      <NaiveBox>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.left}
              onClick={() => {
                props.onCheckBoxClicked("left");
              }}
            />
          }
          label="左"
        />
        <TextField value={props.suggestedWords.left} disabled />
      </NaiveBox>
      <NaiveBox>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.right}
              onClick={() => {
                props.onCheckBoxClicked("right");
              }}
            />
          }
          label="右"
        />
        <TextField value={props.suggestedWords.right} disabled />
      </NaiveBox>
    </FormGroup>
  );
};

const NaiveBox = (props: { children: ReactNode }) => {
  return (
    <div
      style={{
        display: "flex",
        // flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
      }}
    >
      {props.children}
    </div>
  );
};
