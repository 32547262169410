export const JAPANESE_SYLLABARY_PATTERN = [
  ["あ", "い", "う", "え", "お"],
  ["か", "き", "く", "け", "こ"],
  ["さ", "し", "す", "せ", "そ"],
  ["た", "ち", "つ", "て", "と"],
  ["な", "に", "ぬ", "ね", "の"],
  ["は", "ひ", "ふ", "へ", "ほ"],
  ["ま", "み", "む", "め", "も"],
  ["や", undefined, "ゆ", undefined, "よ"],
  ["ら", "り", "る", "れ", "ろ"],
  ["わ", undefined, undefined, undefined, "を"],
  ["ん", undefined, undefined, undefined, undefined],
] as const;
