import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

export const IncludePage = () => {
  const [targetWord, setTargetWord] = useState<string>("");
  const [include, setInclude] = useState<boolean>(false);

  useEffect(() => {}, [targetWord]);

  return (
    <div>
      <h1>Include Page</h1>
      <TextField
        value={targetWord}
        label={"単語"}
        onChange={(e) => {
          setTargetWord(e.target.value);
        }}
        color={include ? "success" : "error"}
      />
    </div>
  );
};
