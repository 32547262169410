import { Outlet } from "react-router-dom";
import { Header } from "src/components/organisms/header/header";

/**
 * for react-router.
 */
export const HeaderTemplate = () => {
  return (
    <div>
      <Header />
      <Outlet />
    </div>
  );
};
