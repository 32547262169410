import { TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { calculateSuggestedWord } from "src/components/pages/japanese_syllabary/calc_char";
import { JAPANESE_SYLLABARY_PATTERN } from "src/components/pages/japanese_syllabary/core";
import {
  Square,
  SquareStatus,
} from "src/components/pages/japanese_syllabary/square";
import {
  Direction,
  UpDownLeftRightCheckBox,
} from "src/components/pages/japanese_syllabary/udlr_check_box";
import { useMobile, useTablet } from "src/style/media_query";

export type SuggestedWords = {
  [direction in Direction]: string | undefined;
};

export const JapaneseSyllabaryPage = () => {
  const [targetWord, setTargetWord] = useState<string>("");
  const [suggestedWords, setSuggestedWords] = useState<SuggestedWords>({
    up: undefined,
    down: undefined,
    left: undefined,
    right: undefined,
  });
  const isTablet = useTablet();
  const isMobile = useMobile();
  const [up, setUp] = useState<boolean>(false);
  const [down, setDown] = useState<boolean>(false);
  const [left, setLeft] = useState<boolean>(false);
  const [right, setRight] = useState<boolean>(false);

  const onClickSquare = useCallback((char: string) => {
    setTargetWord((prev) => {
      if (prev.includes(char)) {
        return prev.replace(char, "");
      }
      return prev + char;
    });
  }, []);
  const copiedSyllabary = useMemo(() => {
    return JAPANESE_SYLLABARY_PATTERN.map((row) => {
      return row.map((char) => {
        return char;
      });
    });
  }, []);
  useEffect(() => {
    setSuggestedWords({
      up: up
        ? calculateSuggestedWord(targetWord, "up", copiedSyllabary)
        : undefined,
      down: down
        ? calculateSuggestedWord(targetWord, "down", copiedSyllabary)
        : undefined,
      left: left
        ? calculateSuggestedWord(targetWord, "left", copiedSyllabary)
        : undefined,
      right: right
        ? calculateSuggestedWord(targetWord, "right", copiedSyllabary)
        : undefined,
    });
  }, [targetWord, up, down, left, right, copiedSyllabary]);

  if (isMobile) {
    return (
      <>
        <div style={{ padding: "15px" }}>
          Mobile
          <TextField
            value={targetWord}
            label={"単語"}
            onChange={(e) => {
              setTargetWord(e.target.value);
            }}
          />
          <UpDownLeftRightCheckBox
            up={up}
            down={down}
            left={left}
            right={right}
            suggestedWords={suggestedWords}
            onCheckBoxClicked={(direction) => {
              switch (direction) {
                case "up":
                  setUp((prev) => !prev);
                  break;
                case "down":
                  setDown((prev) => !prev);
                  break;
                case "left":
                  setLeft((prev) => !prev);
                  break;
                case "right":
                  setRight((prev) => !prev);
                  break;
              }
            }}
          />
        </div>
        <JapaneseSyllabaryTable
          onClick={onClickSquare}
          targetWord={targetWord}
          suggestedWords={suggestedWords}
        />
      </>
    );
  }
  if (isTablet) {
    return (
      <>
        <div style={{ padding: "15px", width: "100%" }}>
          Tablet
          <TextField
            value={targetWord}
            label={"単語"}
            onChange={(e) => {
              setTargetWord(e.target.value);
            }}
          />
          <UpDownLeftRightCheckBox
            suggestedWords={suggestedWords}
            up={up}
            down={down}
            left={left}
            right={right}
            onCheckBoxClicked={(direction) => {
              switch (direction) {
                case "up":
                  setUp((prev) => !prev);
                  break;
                case "down":
                  setDown((prev) => !prev);
                  break;
                case "left":
                  setLeft((prev) => !prev);
                  break;
                case "right":
                  setRight((prev) => !prev);
                  break;
              }
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <JapaneseSyllabaryTable
            onClick={onClickSquare}
            targetWord={targetWord}
            suggestedWords={suggestedWords}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div style={{ padding: "15px" }}>
        <TextField
          value={targetWord}
          label={"単語"}
          onChange={(e) => {
            setTargetWord(e.target.value);
          }}
        />
        <UpDownLeftRightCheckBox
          suggestedWords={suggestedWords}
          up={up}
          down={down}
          left={left}
          right={right}
          onCheckBoxClicked={(direction) => {
            switch (direction) {
              case "up":
                setUp((prev) => !prev);
                break;
              case "down":
                setDown((prev) => !prev);
                break;
              case "left":
                setLeft((prev) => !prev);
                break;
              case "right":
                setRight((prev) => !prev);
                break;
            }
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <JapaneseSyllabaryTable
          onClick={onClickSquare}
          targetWord={targetWord}
          suggestedWords={suggestedWords}
        />
      </div>
    </>
  );
};

/**
 * この関数は呼ばれすぎなので、メモ化を適切にしてください
 */
const getStatus = (
  targetWord: string,
  suggestedWords: SuggestedWords,
  char: string
): SquareStatus => {
  console.count("getStatus");
  if (targetWord.includes(char)) {
    return "active";
  }
  if (suggestedWords.up?.includes(char)) {
    return "suggestedUp";
  }
  if (suggestedWords.down?.includes(char)) {
    return "suggestedDown";
  }
  if (suggestedWords.left?.includes(char)) {
    return "suggestedLeft";
  }
  if (suggestedWords.right?.includes(char)) {
    return "suggestedRight";
  }
  return "inactive";
};
const JapaneseSyllabaryTable = (props: {
  targetWord: string;
  onClick: (char: string) => void;
  suggestedWords: SuggestedWords;
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
      {JAPANESE_SYLLABARY_PATTERN.map((row, i) => {
        return (
          <div
            key={i}
            style={{
              display: "flex",
              flexDirection: "column",
              height: "700px",
            }}
          >
            {row.map((char, j) => {
              return (
                <Square
                  key={`${i}-${j}`}
                  character={char}
                  status={getStatus(
                    props.targetWord,
                    props.suggestedWords,
                    char ?? "-"
                  )}
                  onClick={() => {
                    if (char) {
                      props.onClick(char);
                    }
                  }}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
