import { Direction } from "src/components/pages/japanese_syllabary/udlr_check_box";

type WordTable = (string | undefined)[][];
export const calculateSuggestedWord = (
  word: string,
  direction: Direction,
  wordTable: WordTable
): string => {
  const suggestedWordList = word.split("").map((char) => {
    return calculateSuggestedCharacter(char, direction, wordTable);
  });
  return suggestedWordList.join("");
};
const calculateSuggestedCharacter = (
  char: string,
  direction: Direction,
  wordTable: WordTable
) => {
  const col = wordTable.findIndex((row) => row.includes(char));
  const row = wordTable[col]?.findIndex((c) => c === char);
  if (col === -1 || row === -1) {
    return "";
  }
  switch (direction) {
    case "right":
      if (col === 0) {
        return undefined;
      }
      return wordTable[col - 1][row];
    case "left":
      if (col === wordTable.length - 1) {
        return undefined;
      }
      return wordTable[col + 1][row];
    case "up":
      if (row === 0) {
        return undefined;
      }
      return wordTable[col][row - 1];
    case "down":
      if (row === wordTable[col].length - 1) {
        return undefined;
      }
      return wordTable[col][row + 1];
  }
};
